
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-color: #be3434;
  --primary-dark: #be3434;
  --secondary-color: #9a9da2;
  --secondary-dark:#9a9da2;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Poppins"

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}


.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: #08263b;
}
/*
.hero-text-main{
  color: #be3434;
}

.hero-text-sub{
  color:#9a9da2;
}
*/