
.key-features-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.features{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 14px;
    row-gap: 10px;
}

.section-title{
    color: #be3434;
    font-size: 3rem;
}

@media (min-width: 1440px) {
    .features{
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 20px;
        margin: 0 145px;
    }

    .section-title{
        font-size: 3rem;
    }
}
