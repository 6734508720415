.hero {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 135px;
    column-gap: 15%;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.hero-title{
    color: #be3434;
    font-size: 60px;
}

.hero-subtitle{
    color: #08263b;
    font-size: 40px;
}

.button {
    width: 300px;
    height: 50px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
    background-color: #08263b;
    border: 1px solid #08263b;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .button:hover {
    background: #6f849c;
    border: 1px solid #6f849c;
  }
  
  .line {
    display: block;
    width: 40px;
    height: 3px;
    margin: 5px auto;
    background-color: var(--primary-color);
  }

.hero-image-div {
  width : 50%;
  height : 70%;
  padding : 0;
}

.hero-image {
  width : 100%;
  height : 100%;
}