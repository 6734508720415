.description {
    background-color: #fff2f2;
    margin: auto;
    width: 100%;
}

.description .container {
    width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.description h2 {
    font-size: 3rem;
    color: #be3434;
}

.description .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.line{
   background-color:  #08263b;
}

.description .col-2 p:first-of-type {
    margin: 2rem 0;
}

.description .col-2 p:nth-child(4) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
}

@media screen and (max-width:940px) {
    .description .container {
        grid-template-columns: 1fr;
    }

    .description .container {
        width: 100%;
    }

    .about .container img {
        padding: 0rem;
        width: 90vw;
    }

    .about .container .col-2 {
        padding: 0;
    }

    .about button {
        margin-bottom: 1rem;
    }
}