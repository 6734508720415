
.section-container{
    display: flex;
    flex-direction: column;
}
.image-block{
    display: flex;
    justify-content: center;
}
.text-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 14px;
}
.title{
    color: #be3434;
    font-size: 3rem;
}
.text{
    margin: 0;
    padding: 0px;
    font-size: 16px;
    color: #08263b;
}
.image-description{
    width: 300px;
   
}
.image-context{
    width: 300px;
}

@media (min-width: 750px) {
    .text-block{
        padding: 0 20px;
    }
}


@media (min-width: 1440px) {
    .section-container{
        flex-direction: row;
        column-gap: 24px;
        justify-content: center;
        margin: 80px 135px 80px 135px;
    }

    .image-block{
        min-width: 400px;
    
    }

    .text-block{
       padding: 0;
    }

    .title{
        font-size: 3rem;
    }

    .text{
        font-size: 18px;
        line-height: 27px;
        width: 570px;
    }

    .image-description{
        width: 650px;
        height: 600px;
    }
    
    .image-context{
        width: 650px;
    }
    

}
