.footer {
    width: 100%;
    background-color: #08263b;
    color: white;
    padding: 3rem 1rem;
}

.footer .container {
    max-width: 1240px;
    margin: auto;
    text-align: center;
}

.footer ul {
    display: flex;
    justify-content: center;
}

.footer li {
    padding: 1rem;
}

.footer li a {
    color: #fff;
}



.bottom {
    text-align: center;
    padding-top: 3rem;
}







