.wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.accordion {
    padding: 0.3vw;
    width: 70%;
}

.item {
    background: #ffffff;
    border: 2px solid #be3434;
    border-radius: 10px;
    margin: 1px;
    margin-bottom: 1rem;
    padding: 15px 30px;
}

.title {
    color: #be3434;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.wrapper h2 {
    font-size: 3rem;
    text-align: center;
    color: #be3434;
}

.wrapper h3 {
    font-size: 1.5rem;
    text-align: center;
    color: #be3434;
}

.faqContent {
    color: #8b7f75;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.show {
    height: auto;
    max-height: 999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}